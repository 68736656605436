// extracted by mini-css-extract-plugin
export var conjugatedWord = "verb-module--conjugatedWord--IPcEq";
export var conjugation = "verb-module--conjugation--Std8W";
export var conjugationGroup = "verb-module--conjugationGroup--33FfW";
export var disabled = "verb-module--disabled--VtaxX";
export var filters = "verb-module--filters--NJWyF";
export var formality = "verb-module--formality--tOvX8";
export var tag = "verb-module--tag--+HhgN";
export var tense = "verb-module--tense--ggw2t";
export var title = "verb-module--title--OUnFB";
export var word = "verb-module--word--ngr1D";