import { Button, ButtonGroup } from '@mui/material';
import { HeadProps } from 'gatsby';
import * as React from 'react';
import { generateConjugations, isConjunctive } from '../../conjugation-engine/conjugation-engine';
import { ConjugationTypes, Formalities, Tenses, verbs } from '../../data/conjugation';
import { Conjugation, Verb } from '../../interfaces/verb';
import {
  conjugatedWord,
  conjugation,
  conjugationGroup,
  disabled,
  filters,
  formality,
  tag,
  tense,
  title,
  word,
} from './verb.module.scss';

const filterByState = (conjugations: Conjugation[], tenseFilters: string[], formalityFilters: string[]) => {
  return conjugations
    .filter((c) =>
      tenseFilters.find((t) => {
        return (
          !c.tense ||
          t === c.tense?.code ||
          ((c.tense?.code === Tenses.FutureProbable.code || c.tense?.code === Tenses.FuturePresumptive.code) &&
            t === Tenses.Future.code)
        );
      })
    )
    .filter((c) =>
      formalityFilters.find((t) => {
        return !c.formality || t === c.formality?.code;
      })
    );
};

const generateConjugationGroup = (conjugations, label, conjugationFilter, tenseFilters, formalityFilters) => {
  return (
    <div>
      <h4 className={title}>{label}</h4>
      <div className={conjugationGroup}>
        {filterByState(conjugations.conjugations.filter(conjugationFilter), tenseFilters, formalityFilters).map((c) => {
          return (
            <div key={`${c.conjugation?.code}${c.tense?.code}${c.formality?.code}`} className={conjugatedWord}>
              <div className={word}>{`${c.conjugatedWord.korean}`}</div>
              <div className={`${tag} ${conjugation}`}>{c.conjugation?.label}</div>
              {c.tense ? <div className={`${tag} ${tense}`}>{c.tense?.label}</div> : null}
              {c.formality ? <div className={`${tag} ${formality}`}>{c.formality?.label}</div> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const VerbPage = (props) => {
  const [tenseFilters, setTenseFilters] = React.useState([Tenses.Past.code, Tenses.Present.code, Tenses.Future.code]);
  const [formalityFilters, setFormalityFilters] = React.useState([
    Formalities.Informal.code,
    Formalities.Polite.code,
    Formalities.Neutral.code,
    Formalities.Deferrential.code,
  ]);

  const verbParam = props.verb ?? props.params.verb;
  const verb: Verb = verbs.find((v) => !verbParam || v.dictionaryForm.korean === verbParam) ?? {
    dictionaryForm: {
      korean: verbParam,
      pronunciationGuide: '',
    },
  };

  const conjugations = generateConjugations(verb);

  const pastActive = tenseFilters.find((t) => t === Tenses.Past.code);
  const presentActive = tenseFilters.find((t) => t === Tenses.Present.code);
  const futureActive = tenseFilters.find((t) => t === Tenses.Future.code);
  const InformalActive = formalityFilters.find((t) => t === Formalities.Informal.code);
  const politeActive = formalityFilters.find((t) => t === Formalities.Polite.code);
  const neutralActive = formalityFilters.find((t) => t === Formalities.Neutral.code);
  const deferrentialActive = formalityFilters.find((t) => t === Formalities.Deferrential.code);

  return (
    <section>
      <h3 className={title}>{verb.dictionaryForm.korean}</h3>
      <div>
        {verb.english?.map((e, index) => (
          <div key={index}>
            {index + 1}. {e}
          </div>
        ))}
      </div>
      <div className={filters}>
        <div>
          <ButtonGroup className="buttonGroup" variant="contained" aria-label="Tense filters">
            <Button
              className={`${tense} ${pastActive ? '' : disabled}`}
              onClick={() => {
                if (pastActive) setTenseFilters(tenseFilters.filter((t) => t !== Tenses.Past.code));
                else setTenseFilters([...tenseFilters, Tenses.Past.code]);
              }}
            >
              {Tenses.Past.label}
            </Button>
            <Button
              className={`${tense} ${presentActive ? '' : disabled}`}
              onClick={() => {
                if (presentActive) setTenseFilters(tenseFilters.filter((t) => t !== Tenses.Present.code));
                else setTenseFilters([...tenseFilters, Tenses.Present.code]);
              }}
            >
              {Tenses.Present.label}
            </Button>
            <Button
              className={`${tense} ${futureActive ? '' : disabled}`}
              onClick={() => {
                if (futureActive) setTenseFilters(tenseFilters.filter((t) => t !== Tenses.Future.code));
                else setTenseFilters([...tenseFilters, Tenses.Future.code]);
              }}
            >
              {Tenses.Future.label}
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <ButtonGroup className="buttonGroup" variant="contained" aria-label="Formality filters">
            <Button
              className={`${formality} ${InformalActive ? '' : disabled}`}
              onClick={() => {
                if (InformalActive)
                  setFormalityFilters(formalityFilters.filter((t) => t !== Formalities.Informal.code));
                else setFormalityFilters([...formalityFilters, Formalities.Informal.code]);
              }}
            >
              {Formalities.Informal.label}
            </Button>
            <Button
              className={`${formality} ${politeActive ? '' : disabled}`}
              onClick={() => {
                if (politeActive) setFormalityFilters(formalityFilters.filter((t) => t !== Formalities.Polite.code));
                else setFormalityFilters([...formalityFilters, Formalities.Polite.code]);
              }}
            >
              {Formalities.Polite.label}
            </Button>
            <Button
              className={`${formality} ${neutralActive ? '' : disabled}`}
              onClick={() => {
                if (neutralActive) setFormalityFilters(formalityFilters.filter((t) => t !== Formalities.Neutral.code));
                else setFormalityFilters([...formalityFilters, Formalities.Neutral.code]);
              }}
            >
              {Formalities.Neutral.label}
            </Button>
            <Button
              className={`${formality} ${deferrentialActive ? '' : disabled}`}
              onClick={() => {
                if (deferrentialActive)
                  setFormalityFilters(formalityFilters.filter((t) => t !== Formalities.Deferrential.code));
                else setFormalityFilters([...formalityFilters, Formalities.Deferrential.code]);
              }}
            >
              {Formalities.Deferrential.label}
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Declarative.label,
        (c) => c.conjugation?.code === ConjugationTypes.Declarative.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Interrogative.label,
        (c) => c.conjugation?.code === ConjugationTypes.Interrogative.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Propositive.label,
        (c) => c.conjugation?.code === ConjugationTypes.Propositive.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Imperative.label,
        (c) => c.conjugation?.code === ConjugationTypes.Imperative.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Nominal.label,
        (c) => c.conjugation?.code === ConjugationTypes.Nominal.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        ConjugationTypes.Adnominal.label,
        (c) => c.conjugation?.code === ConjugationTypes.Adnominal.code,
        tenseFilters,
        formalityFilters
      )}
      {generateConjugationGroup(
        conjugations,
        'Conjunctive',
        (c) => isConjunctive(c.conjugation?.code),
        tenseFilters,
        formalityFilters
      )}
    </section>
  );
};

export default VerbPage;

export function Head(props: HeadProps) {
  return <title>{props.params.verb}</title>;
}
